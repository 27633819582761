import { useQuery } from '@tanstack/react-query'

import { ApiPaths } from '@/utils/apiPaths'
import { fetchAllCities } from '@/utils/parkingsService'

import { AllCitiesParams } from '../parkings'

export const useFetchAllCities = ({ params }: { params: AllCitiesParams }) => {
  const { data: cities, isLoading } = useQuery({
    queryKey: [ApiPaths.API_V1_CITIES, params],
    queryFn: () =>
      fetchAllCities({
        params
      })
  })

  return {
    cities,
    isLoading
  }
}
