import React from 'react'
import { useTranslation } from 'next-i18next'

import { Icon } from './Burger.styled'

interface Props {
  open: boolean
  short?: boolean
}

function Burger(props: Props) {
  const { open, short = false } = props
  const { t } = useTranslation('common')

  return (
    <Icon
      open={open}
      short={short}
      aria-label={open ? t('a11y.burgerClose') : t('a11y.burgerOpen')}
      aria-expanded={open}
    />
  )
}

export default Burger
