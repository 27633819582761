import * as Sentry from '@sentry/nextjs'

import axios from 'axios'

import {
  AllFaqParams,
  AllFaqResponse,
  CategoriesFaqParams,
  CategoriesFaqResponse,
  instance
} from '@/service'

import { ApiPaths } from './apiPaths'

export const fetchFaqData = async ({ params }: { params?: AllFaqParams }) => {
  try {
    const { data } = await axios.get<AllFaqResponse>(`${ApiPaths.API_V1_FAQ}`, {
      params
    })
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const fetchFaqCategoriesData = async ({
  params
}: {
  params?: CategoriesFaqParams
}) => {
  try {
    const { data } = await instance.get<CategoriesFaqResponse>(
      ApiPaths.API_V1_FAQ_CATEGORIES,
      {
        params
      }
    )
    return data
  } catch (e) {
    Sentry.captureException(e)
  }
}
