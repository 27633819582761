import React from 'react'

import { css } from '@emotion/react'

import { mediaUp, mediaDown } from '@/styles/media'
import { border, colors, transitions } from '@/styles/theme'

interface Props {
  click: boolean
  hover: boolean
  linksContent: React.ReactNode
  mainLinkContent?: React.ReactNode
}

function Submenu(props: Props) {
  const { click = false, hover = false, linksContent, mainLinkContent } = props

  return (
    <div
      css={[
        submenu,
        hover && submenuHoverActive,
        click && submenuClickActive,
        !mainLinkContent && submenuWithoutMainLink
      ]}
    >
      <div css={submenuInner}>
        {mainLinkContent && <div css={mainLink}>{mainLinkContent}</div>}
        {linksContent}
      </div>
    </div>
  )
}

const submenu = css`
  position: relative;
  list-style: none;

  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    bottom: calc(100% - 11px);
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    border-color: transparent;
    border-bottom-color: ${colors.darkSkyBlue};
    border-width: 12px;
    margin-inline-start: -12px;
  }

  ${mediaDown('lg')} {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
  }

  ${mediaUp('lg')} {
    position: absolute;
    top: 100%;
    left: 50%;
    transition: opacity ${transitions.base}, transform ${transitions.base};
    transform: translate(-50%, 10px);
    padding-top: 7px;
    opacity: 0;
    visibility: hidden;
  }
`

const submenuWithoutMainLink = css`
  &::after {
    border-bottom-color: ${colors.white};

    ${mediaDown('lg')} {
      content: none;
    }
  }
`

const submenuInner = css`
  display: flex;
  flex-direction: column;

  ${mediaDown('lg')} {
    align-items: center;
  }

  ${mediaUp('lg')} {
    border-radius: ${border.radius};
    box-shadow: ${border.shadow};
    background-color: ${colors.white};
    overflow: hidden;
  }
`

const submenuHoverActive = css`
  ${mediaUp('lg')} {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
  }
`

const submenuClickActive = css`
  ${mediaDown('lg')} {
    max-height: 300px;
    transition: max-height ${transitions.base} ease-out;
  }
`

const mainLink = css`
  padding: 0px 10px;

  ${mediaUp('lg')} {
    background-color: ${colors.darkSkyBlue};
    padding: 18px 20px;
    white-space: nowrap;
  }
`

export default Submenu
