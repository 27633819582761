import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'

import { css } from '@emotion/react'

import vh from '@/utils/100vh'

import Link from '@/components/common/Link'
import { Grid } from '@/styles/grid'
import Logo from '@/components/navigation/Logo'
import Burger from '@/components/navigation/Burger'
import Links from '@/components/navigation/Links'

import { useFetchCustomizedClients } from '@/service/apiHooks/useFetchCustomizedClients'

import {
  Breadcrumb,
  BurgerWrapper,
  Container,
  LinksListWrapper,
  LogoWrapper,
  NavigationContainer
} from './Navbar.styled'

interface Props {
  short?: boolean
  highlight?: boolean
  breadcrumbsText?: string
}

function Navbar(props: Props) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const {
    short = false,
    highlight = router.query.slug === 'legia-warszawa',
    breadcrumbsText = ''
  } = props
  const [open, setOpen] = useState(false)

  const [scrolled, setScrolled] = useState(false)
  const { id } = router.query as Params
  const { clientDetails } = useFetchCustomizedClients({
    id
  })
  const isLegia = router.query.parking === 'legia-warszawa'

  function handleScroll() {
    setScrolled(window.pageYOffset > 10)
  }

  function toggle() {
    setOpen(!open)
  }

  function close() {
    router.push({ pathname: '/' })
  }

  useEffect(() => {
    const root = document.getElementsByTagName('html')
    root[0].classList.toggle('menu-opened', open)
  }, [open])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    vh.init()

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container scrolled={scrolled || short || highlight}>
      <Grid>
        <NavigationContainer scrolled={scrolled}>
          {clientDetails?.logoUrl ? (
            <Link
              href={
                clientDetails?.free
                  ? { pathname: router.pathname, query: { ...router.query } }
                  : { pathname: '/' }
              }
              passHref
              prefetch={false}
            >
              <LogoWrapper
                as="a"
                onClick={() => setOpen(false)}
                aria-label={t('a11y.pageName')}
              >
                <img src={clientDetails?.logoUrl} css={logoImgStyle} />
              </LogoWrapper>
            </Link>
          ) : isLegia ? (
            <Link href={{ pathname: '/' }} passHref prefetch={false}>
              <LogoWrapper
                as="a"
                onClick={() => setOpen(false)}
                aria-label={t('a11y.pageName')}
                narrow
              >
                <img
                  src={
                    'https://cdn.naviparking.com/digital_parking/img/logo-legia.webp'
                  }
                  css={logoImgStyle}
                />
              </LogoWrapper>
            </Link>
          ) : (
            <Link href={{ pathname: '/' }} passHref prefetch={false}>
              <LogoWrapper
                as="a"
                onClick={() => setOpen(false)}
                aria-label={t('a11y.pageName')}
                data-test-id={'menu-logo'}
              >
                <Logo open={open} />
              </LogoWrapper>
            </Link>
          )}
          {short && breadcrumbsText && (
            <Breadcrumb>| {breadcrumbsText}</Breadcrumb>
          )}
          <BurgerWrapper onClick={short ? close : toggle}>
            <Burger short={short} open={open} />
          </BurgerWrapper>
          <LinksListWrapper open={open}>
            <Links short={short} onClick={() => setOpen(false)} />
          </LinksListWrapper>
        </NavigationContainer>
      </Grid>
    </Container>
  )
}

const logoImgStyle = css`
  width: 100%;
  height: 100%;
`

export default Navbar
