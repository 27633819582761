import dynamic from 'next/dynamic'

import { useMarket } from '@/utils/multi-markets/context'

const DigitalParkingLogo = dynamic(
  () => import('@/assets/digital-parking-logo.svg?inline')
)
const CyfrowyParkingLogo = dynamic(
  () => import('@/assets/cyfrowy-parking-logo.svg?inline')
)

const components = {
  en: DigitalParkingLogo,
  pl: CyfrowyParkingLogo,
  de: DigitalParkingLogo,
  ar: DigitalParkingLogo
}

function Logo() {
  const { locale } = useMarket()
  const LogoComponent = components[locale]

  return <LogoComponent />
}
export default Logo
