import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import { City } from '@/service'
import Link from '@/components/common/Link'
import ArrowIcon from '@/assets/arrow.svg?inline'

import { mediaUp, mediaDown } from '@/styles/media'
import { colors, weights } from '@/styles/theme'
import { Text2Styles } from '@/styles/typography'
import { focusVisible, underlineHover } from '@/styles/mixins'

import { useMarket } from '@/utils/multi-markets/context'

import { useFetchAllCities } from '@/service/apiHooks/useFetchAllCities'

import Submenu from './Submenu'

interface Props {
  click: boolean
  hover: boolean
  onClick?(): void
}

function ParkingsSubmenu(props: Props) {
  const { t } = useTranslation('common')
  const { click = false, hover = false, onClick } = props
  const { pathname, query } = useRouter()
  const { locale, market } = useMarket()

  const { cities } = useFetchAllCities({
    params: { lang: locale, countryCode: market }
  })

  const mainLinkContent = (
    <Link href={{ pathname: '/parkings' }} passHref prefetch={false}>
      <a
        onClick={onClick}
        css={[
          mainLinkItem,
          (pathname === '/parkings' || pathname === '/parkings/[city]') &&
            linkItemActive
        ]}
      >
        {t('navigation.submenuParkingsTitle')}
        <ArrowIcon />
      </a>
    </Link>
  )
  const linksContent = (
    <ul css={content}>
      {cities?.items?.map((city: City, index) => (
        <li css={link} key={index}>
          <Link
            href={{
              pathname: '/parkings/[city]',
              query: {
                city: city.cityUrlSlug
              }
            }}
            passHref
            prefetch={false}
          >
            <a
              onClick={onClick}
              css={[
                linkItem,
                query.city === city.cityUrlSlug && linkItemActive
              ]}
            >
              {city.label}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )

  return (
    <Submenu
      mainLinkContent={mainLinkContent}
      linksContent={linksContent}
      click={click}
      hover={hover}
    />
  )
}

const mainLinkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  font-weight: ${weights.black};
  color: ${colors.white};
  margin-top: 18px;
  display: flex;
  align-items: center;

  svg {
    margin-inline-start: 4px;

    ${mediaDown('lg')} {
      width: 9px;
      height: 9px;
    }

    html[lang^='ar'] & {
      transform: rotate(180deg);
    }
  }

  ${mediaDown('lg')} {
    font-size: 12px;
  }

  ${mediaUp('lg')} {
    margin-top: 0;
  }
`

const content = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;

  ${mediaUp('lg')} {
    padding: 20px;
    padding-top: 0;

    align-items: flex-start;
  }
`

const link = css`
  display: flex;
  margin-top: 12px;

  ${mediaUp('lg')} {
    margin-top: 20px;
  }
`

const linkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  font-weight: ${weights.black};
  color: ${colors.white};
  line-height: normal;
  white-space: nowrap;

  ${mediaDown('lg')} {
    font-size: 12px;
  }

  ${mediaUp('lg')} {
    color: ${colors.twilightBlue};
    line-height: normal;
  }
`

const linkItemActive = css`
  text-decoration: underline !important;
`

export default ParkingsSubmenu
