import { useQuery } from '@tanstack/react-query'

import { fetchFaqData } from '@/utils/faqService'

import { AllFaqParams } from '../faq'

export const useFetchFaqData = ({
  params,
  enabled = true,
  queryKey = ''
}: {
  params?: AllFaqParams
  enabled?: boolean
  queryKey: any
}) => {
  const {
    data: faqCategories,
    isLoading,
    refetch
  } = useQuery({
    queryKey,
    queryFn: () =>
      fetchFaqData({
        params
      }),
    enabled
  })
  return {
    faqCategories,
    isLoading,
    refetch
  }
}
