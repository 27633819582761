import React from 'react'

import LogoIcon from '@/components/common/Logo'

import { Icon } from './Logo.styled'

interface Props {
  open?: boolean
}

function Logo(props: Props) {
  const { open = false } = props

  return (
    <Icon open={open}>
      <LogoIcon />
    </Icon>
  )
}

export default Logo
