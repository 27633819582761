import styled from '@emotion/styled'

import { colors, transitions } from '@/styles/theme'
import { mediaUp } from '@/styles/media'

export const Icon = styled.div<{ open: boolean }>`
  height: 100%;
  width: 100%;

  svg {
    height: 100%;
    width: 100%;

    path {
      transition: fill ${transitions.base};
    }

    path:nth-of-type(1) {
      fill: ${props => (props.open ? colors.greenishCyan : colors.blueyGrey)};

      ${mediaUp('lg')} {
        fill: ${colors.blueyGrey};
      }
    }

    path:nth-of-type(2) {
      fill: ${props => (props.open ? colors.greenishCyan : colors.darkSkyBlue)};

      ${mediaUp('lg')} {
        fill: ${colors.darkSkyBlue};
      }
    }

    path:nth-of-type(3) {
      fill: ${props => (props.open ? colors.white : colors.twilightBlue)};

      ${mediaUp('lg')} {
        fill: ${colors.twilightBlue};
      }
    }
  }
`
