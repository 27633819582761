import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import { useCallback } from 'react'

import _ from 'lodash'

import Link from '@/components/common/Link'
import ArrowIcon from '@/assets/arrow.svg?inline'

import { mediaUp, mediaDown } from '@/styles/media'
import { colors, weights } from '@/styles/theme'
import { Text2Styles } from '@/styles/typography'
import { focusVisible, underlineHover } from '@/styles/mixins'

import { useFetchFaqData } from '@/service/apiHooks/useFetchFaqData'

import { ApiPaths } from '@/utils/apiPaths'

import { FaqItem } from '@/service'

import Submenu from './Submenu'

interface Props {
  click: boolean
  hover: boolean
  onClick?(): void
}

function FaqSubmenu(props: Props) {
  const { t } = useTranslation('common')
  const { click = false, hover = false, onClick } = props
  const { pathname, query, locale } = useRouter()
  const { faqCategories, isLoading } = useFetchFaqData({
    params: { lang: locale },
    queryKey: [ApiPaths.API_V1_FAQ, 'all']
  })

  const mainLinkContent = (
    <Link href={{ pathname: '/faq' }} passHref prefetch={false}>
      <a
        onClick={onClick}
        css={[
          mainLinkItem,
          (pathname === '/faq' || pathname === '/faq/[category]') &&
            linkItemActive
        ]}
      >
        {t('navigation.submenuFaqsTitle')}
        <ArrowIcon />
      </a>
    </Link>
  )
  const getUniqueCategories = useCallback(
    (data: FaqItem[]) => {
      return data?.reduce((uniqueCategories: any, item: any) => {
        if (!uniqueCategories.includes(item.category)) {
          uniqueCategories.push(item.category)
        }
        return uniqueCategories
      }, [])
    },
    [isLoading]
  )

  const uniqueCategories =
    faqCategories?.items && getUniqueCategories(faqCategories.items)

  const normalizeLink = (input: string) => {
    const inputReplaced = input
      .toLowerCase()
      .replace(' ', '-')
      .replace('/', '-')
    return _.deburr(inputReplaced)
  }

  const linksContent = (
    <ul css={content}>
      {uniqueCategories?.map((faq: any, index: number) => (
        <li css={faqLink} key={index}>
          <Link
            href={{
              pathname: '/faq/[category]',
              query: {
                category: normalizeLink(faq)
              }
            }}
            passHref
            prefetch={false}
          >
            <a
              onClick={onClick}
              css={[
                faqLinkItem,
                query.category === normalizeLink(faq) && linkItemActive
              ]}
            >
              {faq}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )

  return (
    <Submenu
      mainLinkContent={mainLinkContent}
      linksContent={linksContent}
      click={click}
      hover={hover}
    />
  )
}

const mainLinkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  font-weight: ${weights.black};
  color: ${colors.white};
  margin-top: 18px;
  display: flex;
  align-items: center;

  svg {
    margin-inline-start: 4px;

    ${mediaDown('lg')} {
      width: 9px;
      height: 9px;
    }

    html[lang^='ar'] & {
      transform: rotate(180deg);
    }
  }

  ${mediaDown('lg')} {
    font-size: 12px;
  }

  ${mediaUp('lg')} {
    margin-top: 0;
  }
`

const content = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;

  ${mediaUp('lg')} {
    padding: 20px;
    padding-top: 0;

    align-items: flex-start;
  }
`

const faqLink = css`
  display: flex;
  margin-top: 12px;

  ${mediaUp('lg')} {
    margin-top: 20px;
  }
`

const faqLinkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  font-weight: ${weights.black};
  color: ${colors.white};
  line-height: normal;
  white-space: nowrap;

  ${mediaDown('lg')} {
    font-size: 12px;
  }

  ${mediaUp('lg')} {
    color: ${colors.twilightBlue};
    line-height: normal;
  }
`

const linkItemActive = css`
  text-decoration: underline !important;
`

export default FaqSubmenu
