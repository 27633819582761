import styled from '@emotion/styled'

import { border, vars, zIndex, transitions } from '@/styles/theme'
import { mediaColumns } from '@/styles/grid'
import { mediaDown, mediaUp } from '@/styles/media'
import { Text2 } from '@/styles/typography'
import { focusVisible } from '@/styles/mixins'

export const Container = styled.header<{
  scrolled: boolean | BooleanConstructor
}>`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: box-shadow 0.5s ease, background-color 0.5s ease;
  box-shadow: ${props => (props.scrolled ? border.shadowMenu : `0 0 0 0 #fff`)};
  background-color: ${props =>
    props.scrolled ? `var(--tint)` : `transparent`};
  z-index: ${zIndex.header};
`

export const NavigationContainer = styled.nav<{
  scrolled: boolean | BooleanConstructor
}>`
  ${mediaColumns({ xs: [12, 0] })};

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  transition: height ${transitions.base};
  height: ${props => (props.scrolled ? '46px' : vars.headerHeightMobile)};

  ${mediaDown('md')} {
    padding: 0 16px;
  }

  ${mediaUp('lg')} {
    height: ${props => (props.scrolled ? '98px' : vars.headerHeight)};
  }
`

export const LinksListWrapper = styled.nav<{ open: boolean }>`
  position: fixed;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;

  ${props =>
    props.open &&
    `
    opacity: 1;
    pointer-events: all;
  `}

  ${mediaDown('lg')} {
    background-color: var(--accentFirst);
    top: 0;
    left: 0;
    width: 100%;
    height: var(--vh);
  }

  ${mediaUp('lg')} {
    position: relative;
    opacity: 1;
    pointer-events: all;
  }
`

export const LogoWrapper = styled.a<{
  narrow?: boolean
}>`
  ${focusVisible};
  position: relative;
  z-index: ${zIndex.burger};
  width: ${props => (props.narrow ? '28px' : '113px')};
  height: 28px;

  ${mediaUp('lg')} {
    height: 58px;
    width: ${props => (props.narrow ? '45px' : '235px')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const BurgerWrapper = styled.div`
  display: flex;
  cursor: pointer;
  z-index: ${zIndex.burger};

  ${mediaUp('lg')} {
    display: none;
  }
`

export const Breadcrumb = styled(Text2)`
  margin-inline-end: auto;
  margin-inline-start: 20px;

  ${mediaDown('lg')} {
    display: none;
  }
`
