import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

import { css } from '@emotion/react'

import Link from '@/components/common/Link'

import { mediaUp, mediaDown } from '@/styles/media'
import { colors, weights } from '@/styles/theme'
import { Text2Styles } from '@/styles/typography'
import { focusVisible, underlineHover } from '@/styles/mixins'

import Submenu from './Submenu'

interface Props {
  click: boolean
  hover: boolean
  onClick?(): void
}

function LandingSubmenu(props: Props) {
  const { t } = useTranslation('common')
  const { click = false, hover = false, onClick } = props
  const { pathname } = useRouter()

  const linksContent = (
    <ul css={content}>
      <li css={faqLink}>
        <Link href={{ pathname: '/case-studies' }} passHref prefetch={false}>
          <a
            onClick={onClick}
            css={[faqLinkItem, pathname === 'case-studies' && linkItemActive]}
          >
            {t('navigation.caseStudies')}
          </a>
        </Link>
      </li>
      <li css={faqLink}>
        <Link href={{ pathname: '/enterprise' }} passHref prefetch={false}>
          <a
            onClick={onClick}
            css={[faqLinkItem, pathname === 'enterprise' && linkItemActive]}
          >
            {t('navigation.landingEnterprise')}
          </a>
        </Link>
      </li>
      <li css={faqLink}>
        <Link href={{ pathname: '/hospitality' }} passHref prefetch={false}>
          <a
            onClick={onClick}
            css={[faqLinkItem, pathname === 'hospitality' && linkItemActive]}
          >
            {t('navigation.landingHospitality')}
          </a>
        </Link>
      </li>
      <li css={faqLink}>
        <Link href={{ pathname: '/naviparkingAPI' }} passHref prefetch={false}>
          <a
            onClick={onClick}
            css={[faqLinkItem, pathname === 'naviparkingAPI' && linkItemActive]}
          >
            {t('navigation.landingAPI')}
          </a>
        </Link>
      </li>
    </ul>
  )

  return <Submenu linksContent={linksContent} click={click} hover={hover} />
}

const content = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;

  ${mediaUp('lg')} {
    padding: 20px;
    padding-top: 0;

    align-items: flex-start;
  }
`

const faqLink = css`
  display: flex;
  margin-top: 12px;

  ${mediaUp('lg')} {
    margin-top: 20px;
  }
`

const faqLinkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  font-weight: ${weights.black};
  color: ${colors.white};
  line-height: normal;
  white-space: nowrap;

  ${mediaDown('lg')} {
    font-size: 12px;
  }

  ${mediaUp('lg')} {
    color: ${colors.twilightBlue};
    line-height: normal;
  }
`

const linkItemActive = css`
  text-decoration: underline !important;
`

export default LandingSubmenu
