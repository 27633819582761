import { useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import { css } from '@emotion/react'

import { Params } from 'next/dist/shared/lib/router/utils/route-matcher'

import { useMarket } from '@/utils/multi-markets/context'

import Link from '@/components/common/Link'
import LangLink from '@/components/common/LangLink'

import DropdownIcon from '@/assets/small-dropdown.svg?inline'

import { colors, weights, transitions } from '@/styles/theme'
import { mediaDown, mediaUp } from '@/styles/media'
import { Text2Styles } from '@/styles/typography'
import { focusVisible, underline, underlineHover } from '@/styles/mixins'

import { useFetchCustomizedClients } from '@/service/apiHooks/useFetchCustomizedClients'

import FaqSubmenu from './FaqSubmenu'
import LandingSubmenu from './LandingSubmenu'
import ParkingsSubmenu from './ParkingsSubmenu'

interface Props {
  short?: boolean
  onClick?(): void
}

function Links(props: Props) {
  const { t } = useTranslation('common')
  const { short = false, onClick } = props
  const { pathname, query } = useRouter()
  const { locale, market } = useMarket()
  const { id } = query as Params
  const { clientDetails } = useFetchCustomizedClients({
    id
  })
  const [hoverFaqSubmenu, setHoverFaqSubmenu] = useState(false)
  const [clickFaqSubmenu, setClickFaqSubmenu] = useState(false)
  const [hoverLandingSubmenu, setHoverLandingSubmenu] = useState(false)
  const [clickLandingSubmenu, setClickLandingSubmenu] = useState(false)
  const [hoverParkingsSubmenu, setHoverParkingsSubmenu] = useState(false)
  const [clickParkingsSubmenu, setClickParkingsSubmenu] = useState(false)

  return (
    <>
      <ul css={linksList}>
        {short && !clientDetails?.free && (
          <>
            <li css={linksListItem}>
              <Link href={{ pathname: '/' }} passHref prefetch={false}>
                <a
                  onClick={onClick}
                  css={[link.linkItem, pathname === '/' && linkItemActive]}
                >
                  {t('navigation.home')}
                </a>
              </Link>
            </li>
          </>
        )}
        {!short && (
          <>
            <li
              css={[linksListItem, linksListItemWithDropdown]}
              onMouseEnter={() => setHoverParkingsSubmenu(true)}
              onMouseLeave={() => setHoverParkingsSubmenu(false)}
              data-test-id="menu-parkings"
            >
              <button
                onClick={() => setClickParkingsSubmenu(!clickParkingsSubmenu)}
                css={[link.linkItem]}
              >
                {t('navigation.search')}
                <DropdownIcon
                  css={[
                    dropdownIcon,
                    hoverParkingsSubmenu && dropdownIconActive,
                    clickParkingsSubmenu && dropdownIconActive
                  ]}
                />
              </button>
              <ParkingsSubmenu
                onClick={onClick}
                hover={hoverParkingsSubmenu}
                click={clickParkingsSubmenu}
              />
            </li>

            <li css={linksListItem} data-test-id="menu-navipay">
              <Link href={{ pathname: '/navipay' }} passHref prefetch={false}>
                <a
                  onClick={onClick}
                  css={[
                    link.linkItem,
                    pathname === '/navipay' && linkItemActive
                  ]}
                >
                  {t('navigation.navipay')}
                </a>
              </Link>
            </li>

            <li css={linksListItem} data-test-id="menu-park-and-fly">
              <Link
                href={{ pathname: '/park-and-fly' }}
                passHref
                prefetch={false}
              >
                <a
                  onClick={onClick}
                  css={[
                    link.linkItem,
                    pathname === '/park-and-fly' && linkItemActive
                  ]}
                >
                  {t('navigation.parkAndFly')}
                </a>
              </Link>
            </li>

            <li
              css={[linksListItem, linksListItemWithDropdown]}
              onMouseEnter={() => setHoverFaqSubmenu(true)}
              onMouseLeave={() => setHoverFaqSubmenu(false)}
              data-test-id="menu-faq"
            >
              <button
                onClick={() => setClickFaqSubmenu(!clickFaqSubmenu)}
                css={[
                  link.linkItem,
                  (pathname === '/faq' || pathname === '/faq/[category]') &&
                    linkItemActive
                ]}
              >
                {t('navigation.faq')}
                <DropdownIcon
                  css={[
                    dropdownIcon,
                    hoverFaqSubmenu && dropdownIconActive,
                    clickFaqSubmenu && dropdownIconActive
                  ]}
                />
              </button>
              <FaqSubmenu
                onClick={onClick}
                hover={hoverFaqSubmenu}
                click={clickFaqSubmenu}
              />
            </li>

            <li
              css={[linksListItem, linksListItemWithDropdown]}
              onMouseEnter={() => setHoverLandingSubmenu(true)}
              onMouseLeave={() => setHoverLandingSubmenu(false)}
              data-test-id="menu-landings"
            >
              <button
                onClick={() => setClickLandingSubmenu(!clickLandingSubmenu)}
                css={[link.linkItem]}
              >
                {t('navigation.landings')}
                <DropdownIcon
                  css={[
                    dropdownIcon,
                    hoverLandingSubmenu && dropdownIconActive,
                    clickLandingSubmenu && dropdownIconActive
                  ]}
                />
              </button>
              <LandingSubmenu
                onClick={onClick}
                hover={hoverLandingSubmenu}
                click={clickLandingSubmenu}
              />
            </li>

            {market === 'ae' && (
              <li css={linksListItem} data-test-id="menu-events">
                <Link href={{ pathname: '/events' }} passHref prefetch={false}>
                  <a
                    onClick={onClick}
                    css={[
                      link.linkItem,
                      pathname === '/events' && linkItemActive
                    ]}
                  >
                    {t('navigation.events')}
                  </a>
                </Link>
              </li>
            )}

            <li css={linksListItem} data-test-id="menu-blog">
              <Link
                href={`https://blog.digitalparking.city/${
                  locale === 'pl' ? locale : ''
                }`}
                passHref
                prefetch={false}
              >
                <a onClick={onClick} css={link.linkItem} target="_blank">
                  {t('navigation.blog')}
                </a>
              </Link>
            </li>

            <li css={linksListItem} data-test-id="menu-contact">
              <Link href={{ pathname: '/contact' }} passHref prefetch={false}>
                <a
                  onClick={onClick}
                  css={[
                    link.linkItem,
                    pathname === '/contact' && linkItemActive
                  ]}
                >
                  {t('navigation.contact')}
                </a>
              </Link>
            </li>
          </>
        )}
        <li css={[linksListItem, onlyDesktop]}>
          <LangLink short={short} />
        </li>
      </ul>
      <div css={onlyMobile}>
        <LangLink short={short} />
      </div>
    </>
  )
}

const linksList = css`
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;

  ${mediaDown('lg')} {
    margin: 82px 0;
    flex: 1;
    align-items: center;
    flex-direction: column;
    overflow: auto;
  }
`

const linksListItem = css`
  margin: 9px 0;

  ${mediaUp('lg')} {
    margin-inline-start: 30px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const linksListItemWithDropdown = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const linkItem = css`
  ${focusVisible};
  ${Text2Styles};
  ${underlineHover};
  color: var(--main);
  cursor: pointer;

  ${mediaDown('lg')} {
    font-weight: ${weights.black};
    font-size: 18px;
    line-height: 1.44;
    color: ${colors.white};
  }
`

const linkItemActive = css`
  ${underline};
`

const link = { linkItem, linkItemActive }

const dropdownIcon = css`
  margin-inline-start: 4px;
  transition: transform ${transitions.base};
  transform: rotate(0);

  > path {
    fill: ${colors.white};
  }

  ${mediaUp('lg')} {
    > path {
      fill: var(--main);
    }
  }
`

const dropdownIconActive = css`
  transform: rotate(180deg);
`

const onlyDesktop = css`
  ${mediaDown('lg')} {
    display: none;
  }
`

const onlyMobile = css`
  ${mediaUp('lg')} {
    display: none;
  }
`

export default Links
